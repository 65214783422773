var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    class: _vm.sizeClass,
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("dashboard.information")) + " ")]
        },
        proxy: true,
      },
      {
        key: "editor",
        fn: function () {
          return [
            _c(
              "a",
              { attrs: { id: "PROJECT_INFO_EDIT" }, on: { click: _vm.onEdit } },
              [_c("i", { staticClass: "far fa-pen-to-square" })]
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "PROJECT_INFO_EDIT",
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("project.title_detail")) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "info-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "info-box left",
                    staticStyle: { width: "50%" },
                  },
                  [
                    _c("div", { staticClass: "info-title project" }, [
                      _vm._v(" " + _vm._s(_vm.$t("dashboard.project")) + " "),
                    ]),
                    _c(
                      "v-clamp",
                      {
                        staticClass: "info-content",
                        attrs: { autoresize: "", "max-lines": 1 },
                      },
                      [_vm._v(" " + _vm._s(_vm.project.name) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "info-box left",
                    staticStyle: { width: "30%" },
                  },
                  [
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.status"))),
                    ]),
                    _c("div", { staticClass: "info-content" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.project.stage ? _vm.project.stage.name : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "info-box", staticStyle: { width: "20%" } },
                  [
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.priority"))),
                    ]),
                    _c("div", { staticClass: "info-content" }, [
                      _vm._v(_vm._s(_vm.project.priority) + " "),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "info-box" },
                [
                  _c("div", { staticClass: "info-title" }, [
                    _vm._v(_vm._s(_vm.$t("project.field.description"))),
                  ]),
                  _c(
                    "v-clamp",
                    {
                      staticClass: "info-content desc-wrap",
                      attrs: { autoresize: "", "max-lines": 3 },
                    },
                    [_vm._v(" " + _vm._s(_vm.project.description) + " ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "info-box date" }, [
                _c("div", { staticClass: "date-start" }, [
                  _c("div", { staticClass: "info-title" }, [
                    _vm._v(_vm._s(_vm.$t("project.field.scheduleStart"))),
                  ]),
                  _c("div", { staticClass: "info-content" }, [
                    _vm._v(_vm._s(_vm.start)),
                  ]),
                ]),
                _c("div", { staticClass: "date-remain" }, [
                  _vm._v(" " + _vm._s(_vm.daysRemaining) + " "),
                ]),
                _c("div", { staticClass: "date-end" }, [
                  _c("div", { staticClass: "info-title" }, [
                    _vm._v(_vm._s(_vm.$t("project.field.scheduleFinish"))),
                  ]),
                  _c("div", { staticClass: "info-content" }, [
                    _vm._v(_vm._s(_vm.end)),
                  ]),
                ]),
                _c("div", { staticClass: "date-progress" }, [
                  _c("div", { staticClass: "chart-bar" }, [
                    _c("div", {
                      staticClass: "chart-bar-progress",
                      style: {
                        width: _vm.progress + "%",
                        backgroundColor: "var(--status-orange)",
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "info-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "info-box left",
                    staticStyle: { width: "50%" },
                  },
                  [
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(_vm.$t("project.field.customer"))),
                    ]),
                    _c(
                      "v-clamp",
                      {
                        staticClass: "info-content",
                        attrs: { autoresize: "", "max-lines": 2 },
                      },
                      [_vm._v(" " + _vm._s(_vm.customer) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "info-box", staticStyle: { width: "50%" } },
                  [
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(_vm.$t("project.field.rebates"))),
                    ]),
                    _c(
                      "v-clamp",
                      {
                        staticClass: "info-content",
                        attrs: { autoresize: "", "max-lines": 2 },
                      },
                      [_vm._v(" " + _vm._s(_vm.rebate) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "info-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "info-box left",
                    staticStyle: { width: "50%" },
                  },
                  [
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.work_completed_by"))),
                    ]),
                    _c(
                      "v-clamp",
                      {
                        staticClass: "info-content",
                        attrs: { autoresize: "", "max-lines": 2 },
                      },
                      [_vm._v(" " + _vm._s(_vm.company) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "info-box", staticStyle: { width: "50%" } },
                  [
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v(_vm._s(_vm.$t("project.field.location"))),
                    ]),
                    _c(
                      "v-clamp",
                      {
                        staticClass: "info-content",
                        attrs: { autoresize: "", "max-lines": 2 },
                      },
                      [_vm._v(" " + _vm._s(_vm.location) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }