import { render, staticRenderFns } from "./HomeMyTasksWidget.vue?vue&type=template&id=e8407b72&scoped=true&"
import script from "./HomeMyTasksWidget.vue?vue&type=script&lang=js&"
export * from "./HomeMyTasksWidget.vue?vue&type=script&lang=js&"
import style0 from "./HomeMyTasksWidget.vue?vue&type=style&index=0&id=e8407b72&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8407b72",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/janustest/.jenkins/workspace/05_Client_Vue_Release_Pipeline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e8407b72')) {
      api.createRecord('e8407b72', component.options)
    } else {
      api.reload('e8407b72', component.options)
    }
    module.hot.accept("./HomeMyTasksWidget.vue?vue&type=template&id=e8407b72&scoped=true&", function () {
      api.rerender('e8407b72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/Widget/HomeMyTasksWidget.vue"
export default component.exports